// Styles for dialog window
.mfp-wrap
  -webkit-overflow-scrolling: touch
  -webkit-transform: translateZ(0)

html.mfp-helper
  height: 100%
  body
    overflow: hidden
    height: 100%
    -webkit-transform: translateZ(0)

.modal
  text-align: left
  max-width: 640px
  margin: 20px auto
  position: relative
  .mfp-close
    font-size: 0
    background: url('../images/el/modal-close@2x.png') no-repeat center
    width: 34px
    height: 29px
    background-size: contain
    opacity: 1
    transition: all $transitionSpeed $ease
    &:hover
      opacity: .7
  &__wrapper
    max-width: 600px
    position: relative
    padding-top: 45px
    padding-left: 60px
    padding-bottom: 75px
    &::before
      background-image: url('../images/el/buyformRose@2x.png')
      position: absolute 0
      z-index: 1
      display: block
      content: ' '
      background-repeat: no-repeat
      background-position: left center
      background-size: contain
  &__content
    position: relative
    z-index: 2
    background: white
    box-shadow: 0px 12px 15px color($fontColor a(25%))
    border-radius: 3px

  &__form
    padding: 70px 70px 45px 70px
    .ui-checkbox
      margin-top: 45px

  &__bottom
    a
      display: block
      background: #F1F1F1
      font-size: 17px
      padding: 22px 60px
      transition: all $transitionSpeed $ease
      &:hover
        background: #F4F4F4
        color: $colorPrimary

@media($mobile)
  .modal
    &__form
      padding: 50px 30px 35px 30px
    &__bottom
      a
        font-size: 16px
        padding: 20px 30px



@media($mobile-s)
  .modal
    &__form
      padding: 40px 20px 30px 20px
    &__bottom
      a
        padding: 20px 20px


// Fade-zoom animation for first dialog

.popup-buble
  .popup-dialog
    opacity: 0
    transition: all 0.2s ease-in-out
    transform: scale(0.8)
  &.mfp-ready .popup-dialog
    opacity: 1
    transform: scale(1)
  &.mfp-removing .popup-dialog
    transform: scale(0.8)
    opacity: 0
  &.mfp-bg
    background: white
    opacity: 0
    transition: opacity 0.3s $ease
  &.mfp-ready.mfp-bg
    opacity: .9
  &.mfp-removing.mfp-bg
    opacity: 0



// Fade-move animation
.my-mfp-slide-bottom
  .zoom-anim-dialog
    opacity: 0
    transition: all 0.2s ease-out
    transform: translateY(-20px) perspective(600px) rotateX(10deg)
  &.mfp-ready .zoom-anim-dialog
    opacity: 1
    transform: translateY(0) perspective(600px) rotateX(0)
  &.mfp-removing .zoom-anim-dialog
    opacity: 0
    transform: translateY(-10px) perspective(600px) rotateX(10deg)
  &.mfp-bg
    opacity: 0
    transition: opacity 0.3s ease-out
  &.mfp-ready.mfp-bg
    opacity: 0.8
  &.mfp-removing.mfp-bg
    opacity: 0
