// ICONS
.icon
  display: inline-block
  font-style: normal
  content: ' '
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  vertical-align: middle

  &-pinkman
    background-image: url('../images/el/pinkman@2x.png')
    size: 100px 20px
  &-creatives
    background-image: svg-load('../../images/svg/creatives.svg')
    size: 100px 20px
    &::before
      display: none
  &-copy-plus
    background-image: url('../images/el/plus@2x.png')
    size: 15px
    margin: 0 10px

  &-circle
    border: 2px solid white
    size: 100px
    border-radius: 50%

  &-rub
    padding-left: 5px

  &-slide-prev
    size: 36px 63px
    margin-right: 30px
  &-slide-next
    size: 36px 63px

  &-select
    background-image: url('../images/el/select.png')
    size: 10px
    &::before
      display: none

  &-like
    background-image: url('../images/el/like@2x.png')
    size: 28px 23px

  &-facebook
    background-image: url('../images/el/facebook@2x.png')
    size: 40px
  &-vk
    background-image: url('../images/el/vk@2x.png')
    size: 40px
    margin-right: 5px
    margin-left: 3px
  &-insta
    background-image: url('../images/el/instagram@2x.png')
    size: 43px 40px

  &-pending
    background-image: url('../images/el/pending@2x.png')
    size: 12px 20px

  &-pending-check
    background-image: url('../images/el/pending-check@2x.png')
    size: 23px 22px

  &-checked
    background-image: url('../images/el/checked@2x.png')
    size: 19px 16px

  &-edit
    background-image: url('../images/el/edit@2x.png')
    size: 22px 22px

@media($tablet)
  .icon
    &-slide-prev
      size: 23px 40px
      margin-right: 25px
    &-slide-next
      size: 23px 40px
    &-circle
      size: 80px

@media($mobile)
  .icon
    &-circle
      size: 50px

@media($mobile-s)
  .icon
    &-circle
      size: 40px


.ico
  &-message
    background-image: svg-load('../../images/svg/ico-message.svg')
    size: 40px
  &-comment
    background-image: svg-load('../../images/svg/ico-comment.svg')
    size: 40px
  &-discount
    background-image: svg-load('../../images/svg/ico-discount.svg')
    size: 33px
    margin-left: 3px
    margin-top: 3px
  &-practice
    background-image: svg-load('../../images/svg/practice.svg')
    size: 20px
  &-practice-done
    background-image: svg-load('../../images/svg/practice-done.svg')
    size: 20px
  &-practice-pending
    background-image: svg-load('../../images/svg/practice-pending.svg')
    size: 20px
  &-practice-comment
    background-image: svg-load('../../images/svg/practice-comment.svg')
    size: 20px
