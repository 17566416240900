@font-face
  font-family: "IconFont"
  src: url('../fonts/IconFont.eot')
  src: url('../fonts/IconFont.eot?#iefix') format('eot'),
    url('../fonts/IconFont.ttf') format('truetype'),
    url('../fonts/IconFont.woff') format('woff'),
    url('../fonts/IconFont.svg#IconFont') format('svg')
  font-weight: normal
  font-style: normal

.icon:before
  display: inline-block
  font-family: "IconFont" !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale



.icon-comment-arrow-black:before
  content: "\EA01"

.icon-comment-arrow:before
  content: "\EA02"

.icon-creatives:before
  content: "\EA03"

.icon-ico-comment:before
  content: "\EA04"

.icon-ico-discount:before
  content: "\EA05"

.icon-ico-done:before
  content: "\EA06"

.icon-ico-draw:before
  content: "\EA07"

.icon-ico-message:before
  content: "\EA08"

.icon-ico-more:before
  content: "\EA09"

.icon-play:before
  content: "\EA0A"

.icon-practice-comment:before
  content: "\EA0B"

.icon-practice-done:before
  content: "\EA0C"

.icon-practice:before
  content: "\EA0D"

.icon-select:before
  content: "\EA0E"

.icon-warn:before
  content: "\EA0F"
